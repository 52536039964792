.fancybox-enabled {
	overflow: hidden;
}

.fancybox-enabled body {
	overflow: visible;
	height: 100%;
}

.fancybox-is-hidden {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

.fancybox-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99993;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	backface-visibility: hidden;
	transform: translateZ(0);
}

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
	z-index: 99992;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.fancybox-outer {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.fancybox-bg {
	background: rgb(30,30,30);
	opacity: 0;
	transition-duration: inherit;
	transition-property: opacity;
	transition-timing-function: cubic-bezier(.47,0,.74,.71);
}

.fancybox-is-open .fancybox-bg {
	opacity: 0.87;
	transition-timing-function: cubic-bezier(.22,.61,.36,1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption-wrap {
	position: absolute;
	direction: ltr;
	z-index: 99997;
	opacity: 0;
	visibility: hidden;
	transition: opacity .25s, visibility 0s linear .25s;
	box-sizing: border-box;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption-wrap {
	opacity: 1;
	visibility: visible;
	transition: opacity .25s, visibility 0s;
}

.fancybox-infobar {
	top: 0;
	left : 50%;
	margin-left: -79px;
}

.fancybox-infobar__body {
	display: inline-block;
	width: 70px;
	line-height: 44px;
	font-size: 13px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	text-align: center;
	color: #ddd;
	background-color: rgba(30,30,30,0.7);
	pointer-events: none;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-toolbar {
	top: 0;
	right: 0;
}

.fancybox-stage {
	overflow: hidden;
	direction: ltr;
	z-index: 99994;
	-webkit-transform: translate3d(0, 0, 0);
}

.fancybox-slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: auto;
	outline: none;
	white-space: normal;
	box-sizing: border-box;
	text-align: center;
	z-index: 99994;
	-webkit-overflow-scrolling: touch;
	display: none;
	backface-visibility: hidden;
	transition-property: transform, opacity;
	transform-style: preserve-3d;
}

.fancybox-slide::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
	display: block;
}

.fancybox-slide--image {
	overflow: visible;
}

.fancybox-slide--image::before {
	display: none;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
	background: #000;
}

.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
	background: #E5E3DF;
}

.fancybox-slide--next {
	z-index: 99995;
}

.fancybox-slide > * {
	display: inline-block;
	position: relative;
	padding: 0;
	margin: 44px 0;
	border-width: 0;
	vertical-align: middle;
	text-align: left;
	background-color: #fff;
	overflow: auto;
	box-sizing: border-box;
}

.fancybox-slide .fancybox-image-wrap {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	border: 0;
	z-index: 99995;
	background: transparent;
	cursor: default;
	overflow: visible;
	transform-origin: top left;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	backface-visibility: hidden;
}

.fancybox-can-zoomOut .fancybox-image-wrap {
	cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-image-wrap {
	cursor: zoom-in;
}

.fancybox-can-drag .fancybox-image-wrap {
	cursor: grab;
}

.fancybox-is-dragging .fancybox-image-wrap {
	cursor: grabbing;
}

.fancybox-image,
.fancybox-spaceball {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	max-width: none;
	max-height: none;
}

 .fancybox-spaceball {
	z-index: 1;
}

.fancybox-slide--iframe .fancybox-content {
	padding: 0;
	width: 80%;
	height: 80%;
	max-width: calc(100% - 100px);
	max-height: calc(100% - 88px);
	overflow: visible;
	background: #fff;
}

.fancybox-iframe {
	display: block;
	margin: 0;
	padding: 0;
	border: 0;
	width: 100%;
	height: 100%;
	background: #fff;
}

.fancybox-error {
	margin: 0;
	padding: 40px;
	width: 100%;
	max-width: 380px;
	background: #fff;
	cursor: default;
}

.fancybox-error p {
	margin: 0;
	padding: 0;
	color: #444;
	font: 16px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fancybox-close-small {
	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 44px;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	outline: none;
	background: transparent;
	z-index: 10;
	cursor: pointer;
}

.fancybox-close-small:after {
	content: '×';
	position: absolute;
	top : 5px;
	right: 5px;
	width: 30px;
	height: 30px;
	font: 20px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
	color: #888;
	font-weight: 300;
	text-align: center;
	border-radius: 50%;
	border-width: 0;
	background: #fff;
	transition: background .25s;
	box-sizing: border-box;
	z-index: 2;
}

.fancybox-close-small:focus:after {
	outline: 1px dotted #888;
}

.fancybox-close-small:hover:after {
	color: #555;
	background: #eee;
}

.fancybox-slide--iframe .fancybox-close-small {
	top : 0;
	right : -44px;
}

.fancybox-slide--iframe .fancybox-close-small:after {
	background: transparent;
	font-size: 35px;
	color: #aaa;
}

.fancybox-slide--iframe .fancybox-close-small:hover:after {
	color: #fff;
}


/* Caption */

.fancybox-caption-wrap {
	bottom: 0;
	left: 0;
	right: 0;
	padding: 60px 30px 0 30px;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 20%, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.8) 100%);
	pointer-events: none;
}

.fancybox-caption {
	padding: 30px 0;
	border-top: 1px solid rgba(255,255,255,0.4);
	font-size: 14px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	color: #fff;
	line-height: 20px;
	-webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
	pointer-events: all;
}

.fancybox-caption a {
	color: #fff;
	text-decoration: underline;
}


/* Buttons */

.fancybox-button {
	display: inline-block;
	position: relative;
	margin: 0;
	padding: 0;
	border: 0;
	width: 44px;
	height: 44px;
	line-height: 44px;
	text-align: center;
	background: transparent;
	color: #ddd;
	border-radius: 0;
	cursor: pointer;
	vertical-align: top;
	outline: none;
}

.fancybox-button[disabled] {
	cursor: default;
	pointer-events: none;
}

.fancybox-infobar__body, .fancybox-button {
	/*background: rgba(30,30,30,0.6);*/
	background: rgba(0,0,0,0.8);
}

.fancybox-button:hover:not([disabled]) {
	color: #fff;
	/*background: rgba(0,0,0,0.8);*/
}

.fancybox-button::before,
.fancybox-button::after {
	content: '';
	pointer-events: none;
	position: absolute;
	background-color: currentColor;
	color: currentColor;
	/*opacity: 0.9;*/
	box-sizing: border-box;
	display: inline-block;
}

.fancybox-button[disabled]::before,
.fancybox-button[disabled]::after {
	opacity: 0.3;
}

.fancybox-button--left::after,
.fancybox-button--right::after {
	top: 18px;
	width: 6px;
	height: 6px;
	background: transparent;
	border-top: solid 2px currentColor;
	border-right: solid 2px currentColor;
}

.fancybox-button--left::after {
	left: 20px;
	transform: rotate(-135deg);
}

.fancybox-button--right::after {
	right: 20px;
	transform: rotate(45deg);
}

.fancybox-button--left {
	border-bottom-left-radius: 5px;
}

.fancybox-button--right {
	border-bottom-right-radius: 5px;
}

.fancybox-button--close::before, .fancybox-button--close::after {
	content: '';
	display: inline-block;
	position: absolute;
	height: 2px;
	width: 16px;
	top: calc(50% - 1px);
	left: calc(50% - 8px);
}

.fancybox-button--close::before {
	transform: rotate(45deg);
}

.fancybox-button--close::after {
	transform: rotate(-45deg);
}


/* Navigation arrows */

.fancybox-arrow {
	position: absolute;
	top: 50%;
	margin: -50px 0 0 0;
	height: 100px;
	width: 54px;
	padding: 0;
	border: 0;
	outline: none;
	background: none;
	cursor: pointer;
	z-index: 99995;
	opacity: 0;
	user-select: none;
	transition: opacity .25s;
}

.fancybox-arrow::after {
	content : '';
	position: absolute;
	top: 28px;
	width: 44px;
	height: 44px;
	background-color: rgba(30,30,30,0.8);
	background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 24px 24px;
}

.fancybox-arrow--right {
	right: 0;
}

.fancybox-arrow--left {
	left: 0;
	transform: scaleX(-1);
}

.fancybox-arrow--right::after,
.fancybox-arrow--left::after {
	left: 0;
}

.fancybox-show-nav .fancybox-arrow {
	opacity: 0.6;
}

.fancybox-show-nav .fancybox-arrow[disabled] {
	opacity: 0.3;
}


/* Loading indicator */

.fancybox-loading {
	border: 6px solid rgba(100, 100, 100, .4);
	border-top: 6px solid rgba(255, 255, 255, .6);
	border-radius: 100%;
	height: 50px;
	width: 50px;
	animation: fancybox-rotate .8s infinite linear;
	background: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -25px;
	z-index: 99999;
}

@keyframes fancybox-rotate {
	from { transform: rotate( 0deg ); }
	to   { transform: rotate( 359deg ); }
}


/* Transition effects */

.fancybox-animated {
	transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
	transform: translate3d(-100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--next {
	transform: translate3d(100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--current {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

/* transitionEffect: fade */

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
	opacity: 0;
	transition-timing-function: cubic-bezier(.19,1,.22,1);
}

.fancybox-fx-fade.fancybox-slide--current {
	opacity: 1;
}

/* transitionEffect: zoom-in-out */

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
	transform: scale3d(1.5, 1.5, 1.5);
	opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
	transform: scale3d(0.5, 0.5, 0.5);
	opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
	transform: scale3d(1, 1, 1);
	opacity: 1;
}

/* transitionEffect: rotate */

.fancybox-fx-rotate.fancybox-slide--previous {
	transform: rotate(-360deg);
	opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--next {
	transform: rotate(360deg);
	opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--current {
	transform: rotate(0deg);
	opacity: 1;
}

/* transitionEffect: circular */

.fancybox-fx-circular.fancybox-slide--previous {
	transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--next {
	transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
	opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--current {
	transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
	opacity: 1;
}

/* transitionEffect: tube */

.fancybox-fx-tube.fancybox-slide--previous {
	transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
	transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
	transform: translate3d(0, 0, 0) scale(1);
}


/* Styling for Small-Screen Devices */

@media all and (max-width: 800px) {

	.fancybox-infobar {
		left: 0;
		margin-left: 0;
	}

	.fancybox-button--left,
	.fancybox-button--right {
		display: none !important;
	}

	.fancybox-caption {
		padding: 20px 0;
		margin: 0;
	}
}
